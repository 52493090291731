import img1 from "../../public/images/gallery/IMG_0031.jpg"
import img2 from "../../public/images/gallery/IMG_0051.jpg"
import img3 from "../../public/images/gallery/IMG_0054.jpg"
import img4 from "../../public/images/gallery/IMG_8519.jpg"
import img5 from "../../public/images/gallery/IMG_8528.jpg"
import img6 from "../../public/images/gallery/arts club IMG_8517.jpg"
import img7 from "../../public/images/gallery/arts-1811.jpg"
import img8 from "../../public/images/gallery/arts-1827.jpg"
import img9 from "../../public/images/gallery/arts-1828.jpg"
import img10 from "../../public/images/gallery/arts-1830.jpg"
import img11 from "../../public/images/gallery/arts-1918.jpg"
import img12 from "../../public/images/gallery/arts-1943.jpg"
import img13 from "../../public/images/gallery/arts-1963.jpg"
import img14 from "../../public/images/gallery/arts-1979.jpg"
import img15 from "../../public/images/gallery/arts-1986.jpg"
import img16 from "../../public/images/gallery/arts-1993.jpg"
import img17 from "../../public/images/gallery/arts-2036.jpg"
import img18 from "../../public/images/gallery/arts-2059.jpg"
import img19 from "../../public/images/gallery/arts-2079.jpg"
import img20 from "../../public/images/gallery/arts-2080.jpg"
import img21 from "../../public/images/gallery/arts-2086.jpg"
import img22 from "../../public/images/gallery/arts-2123.jpg"
import img23 from "../../public/images/gallery/arts-2206.jpg"
import img24 from "../../public/images/gallery/arts-2220.jpg"
import img25 from "../../public/images/gallery/arts-2234.jpg"
import img26 from "../../public/images/gallery/image003.jpg"
import img27 from "../../public/images/gallery/image008.jpg"

export const IMAGES = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27
]
