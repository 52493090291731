require("expose-loader?$!jquery")
require("expose-loader?jQuery!jquery")
require("expose-loader?angular!angular")

import "../styles/index.scss"
import angular from "angular"
import "slick-carousel/slick/slick"
import "../../libs/angular-slick/dist/slick.js"
import "../../libs/ngSmoothScroll/lib/angular-smooth-scroll.js"
import "../../libs/ngGallery/src/js/ngGallery.js"
import "../../libs/angular-thumbnail/angular-thumbnail"
import pdf from "../../public/hire.pdf"
import menuPdf from "../../public/menu.pdf"
import logo from "../../public/images/logo.png"
import spacer from "../../public/images/spacer.png"
import spacerWhite from "../../public/images/spacer-white.png"
import graphic from "../../public/images/homepage_graphic.png"
import contactImage from "../../public/images/contact-us.png"
import galleryImage from "../../public/images/gallery-image.png"
import { IMAGES as galleryImages } from "./images"
import foodCsv from "../food.csv"
import specialCsv from "../special.csv"
import standardCsv from "../standard.csv"
import uibootstrap from 'angular-ui-bootstrap'

var requiredModules = ["at.vendors", "at.controllers"]

angular.module("at", requiredModules)

angular.module("at.controllers", [
  "at.controllers.contact",
  "at.controllers.modal",
  "at.controllers.gallery",
  "at.controllers.nav",
  "at.controllers.reviews",
  "at.controllers.about",
  "at.controllers.menu"
])

angular.module("at.vendors", [
  "slick",
  "smoothScroll",
  "jkuri.gallery",
  "ui.thumbnail",
  uibootstrap
])

angular.module("at.controllers.menu", []).controller("menu", [
  "$scope",
  function($scope) {
    $scope.activeSlide = 0
    $scope.menuPdf = menuPdf
    $scope.spacerWhite = spacerWhite
    $scope.standardMenu = standardCsv
    $scope.specialMenu = specialCsv
    $scope.foodMenu = foodCsv

    $scope.parseValue = function(value) {
      return parseFloat(value).toFixed(2)
    }

    $(".slick-menu").on("beforeChange", function(
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      if (!$scope.$$phase) {
        $scope.$apply(function() {
          $scope.activeSlide = nextSlide
        })
      }
    })

    $scope.goToSlide = function(index) {
      $(".slick-menu").slick("slickGoTo", index, false)
      $scope.activeSlide = index
    }
  }
])

angular.module("at.controllers.nav", []).controller("nav", [
  "$scope",
  function($scope) {
    $scope.logo = logo
    $scope.hirePdf = pdf
  }
])

angular.module("at.controllers.reviews", []).controller("reviews", [
  "$scope",
  function($scope) {
    $scope.spacer = spacerWhite
  }
])

angular.module("at.controllers.about", []).controller("about", [
  "$scope",
  function($scope) {
    $scope.spacer = spacer
    $scope.graphic = graphic
  }
])

//passsing directoryPath and callback function

angular.module("at.controllers.gallery", []).controller("gallery", [
  "$scope",
  "$window",
  function($scope, $window) {
    $scope.images = []
    var images = galleryImages

    $scope.spacer = spacer
    $scope.galleryImage = galleryImage

    angular.forEach(images, function(image) {
      $scope.images.push({
        img: image,
        thumb: image
      })
    })

    $scope.openLightboxModal = function(index) {
      Lightbox.openModal($scope.images, index)
    }
  }
])

angular.module("at.controllers.contact", []).controller("contact", [
  "$scope",
  function($scope) {
    $scope.spacer = spacer

    $scope.contactImage = contactImage
    $scope.activeSlide = 0

    $(".slick-contact").on("beforeChange", function(
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      if (!$scope.$$phase) {
        $scope.$apply(function() {
          $scope.activeSlide = nextSlide
        })
      }
    })

    $scope.goToSlide = function(index) {
      $(".slick-contact").slick("slickGoTo", index, false)
      $scope.activeSlide = index
    }

    //set your google maps parameters
    var latitude = 51.513636,
      longitude = -0.131726,
      map_zoom = 14

    //google map custom marker icon - .png fallback for IE11
    var is_internetExplorer11 =
      navigator.userAgent.toLowerCase().indexOf("trident") > -1
    var marker_url = is_internetExplorer11
      ? "assets/images/cd-icon-location.png"
      : "assets/images/cd-icon-location.svg"

    //define the basic color of your map, plus a value for saturation and brightness
    var main_color = "#7D4D22",
      saturation_value = -20,
      brightness_value = 5

    //we define here the style of the map
    var style = [
      {
        //set saturation for the labels on the map
        elementType: "labels",
        stylers: [{ saturation: saturation_value }]
      },
      {
        //poi stands for point of interest - don't show these lables on the map
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        //don't show highways lables on the map
        featureType: "road.highway",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        //don't show local road lables on the map
        featureType: "road.local",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        //don't show arterial road lables on the map
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        //don't show road lables on the map
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }]
      },
      //style different elements on the map
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.government",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.sport_complex",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.attraction",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.business",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "transit.station",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "landscape",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      }
    ]

    //set google map options
    var map_options = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: map_zoom,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      styles: style
    }
    //inizialize the map
    var map = new google.maps.Map(
      document.getElementById("google-container"),
      map_options
    )
    //add a custom marker to the map
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map,
      visible: true,
      icon: marker_url
    })

    //add custom buttons for the zoom-in/zoom-out on the map
    function CustomZoomControl(controlDiv, map) {
      //grap the zoom elements from the DOM and insert them in the map
      var controlUIzoomIn = document.getElementById("cd-zoom-in"),
        controlUIzoomOut = document.getElementById("cd-zoom-out")
      controlDiv.appendChild(controlUIzoomIn)
      controlDiv.appendChild(controlUIzoomOut)

      // Setup the click event listeners and zoom-in or out according to the clicked element
      google.maps.event.addDomListener(controlUIzoomIn, "click", function() {
        map.setZoom(map.getZoom() + 1)
      })
      google.maps.event.addDomListener(controlUIzoomOut, "click", function() {
        map.setZoom(map.getZoom() - 1)
      })
    }

    var zoomControlDiv = document.createElement("div")
    var zoomControl = new CustomZoomControl(zoomControlDiv, map)

    //insert the zoom div on the top left of the map
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv)
  }
])

angular.module('at.controllers.modal', []).controller('modal', ["$uibModal", function ($uibModal) {
  var $ctrl = this
  $ctrl.animationsEnabled = true

  $ctrl.open = function () {
    $uibModal.open({
      animation: $ctrl.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'myModalContent.html',
      controller: 'modalInstance',
      controllerAs: 'ctrl',
      size: 'lg',
    })
  }

  setTimeout(() => {
    $ctrl.open()
  },[100])

}])


angular.module('at.controllers.modal').controller('modalInstance', ["$uibModalInstance", function ($uibModalInstance) {
  var $ctrl = this
  $ctrl.ok = function () {
    $uibModalInstance.close()
  }

  $ctrl.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
}])

var widgetCallback = function() {
  var dmnTime = document.getElementById("dmn-time")
  dmnTime.removeAttribute("ng-if")

  angular.bootstrap(document, ["at"])
}

var dmnJs = document.getElementById("dmn-js")
var s = document.createElement("script")
s.setAttribute(
  "src",
  "https://partners.designmynight.com/pf/js?venue_id=512b2037d5d190d2978ca579&widgetNum=1"
)
s.onload = widgetCallback
dmnJs.appendChild(s)
